<template>
    <div class="content-wrapper">
        <bo-page-title>
            <template slot="additionalCta">
                <b-button @click="apiGet()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
                    <b><i class="icon-reload-alt"></i></b> Muat Ulang
                </b-button>
            </template>
        </bo-page-title>
        <!--icon-reload-alt-->
        <div class="content pt-0">
            <ul class="nav nav-justified nav-tabs nav-tabs-highlight mb-0">
                <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'">Antrian UGD</a></li>
                <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" class="nav-link"> Antrian Rawat Inap</a></li>
                <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" class="nav-link"> Antrian Fisioterapi</a></li>
            </ul>
            <div class="tab-content">
                <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabInfo">        
                    <div class="form-row mb-2">
                        <div class="col-md-6">
                            <div class="form-row">
                                <div class="col-md-12 mb-2">
                                <div class="box_call">
                                    <div class="row align-items-center">
                                    <div class="col-md-auto">
                                        <div class="wrap_text">
                                        <p>Tujuan</p>
                                        <h3 v-if="Object.keys(unit||{}).length">{{unit.aru_unit_tujuan||"-"}}</h3>
                                        <h3 v-else> - </h3>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <div class="col-md-12" v-if="(resume.totalAntrean == 0 || !resume.totalSisa) && !resume.isTutup">
                                    <div class="box_call bg_nonaktif" data-popup="tooltip"
                                        title="" data-toggle="modal">
                                        <div class="row align-items-center">
                                        <div class="col-md-auto">
                                            <div class="wrap_text">
                                            <p class="text_light">Tidak ada Antrean</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-12" v-if="resume.isTutup">
                                    <div class="box_call bg_call3" data-popup="tooltip"
                                        title="" data-toggle="modal">
                                        <div class="row align-items-center">
                                        <div class="col-md-auto">
                                            <div class="wrap_text">
                                            <p class="text_light">Loket Admisi Tutup</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" v-if="Object.keys(unit||{}).length && latestAntrean && resume.totalAntrean !== 0 && !resume.isTutup">
                                    <div class="box_call bg_call1" @click="ambilAntrean()" style="cursor:pointer;" data-popup="tooltip"
                                        title="" data-toggle="modal">
                                        <div class="row align-items-center">
                                        <div class="col-md-auto">
                                            <div class="ic_left">
                                            <i class="icon-megaphone"></i>
                                            </div>
                                        </div>
                                        <div class="col-md-auto">
                                            <div class="wrap_text">
                                            <p class="text_light">Panggil Antrean</p>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-row" id="antrian-today">
                                <div class="col-md-12">
                                <div class="card mb-2">
                                    <div class="card-header">
                                    <h5 class="card-title font-weight-semibold">Detail</h5>
                                    </div>
                                    <div class="table-responsive mb-0">
                                    <table class="table  table-bordered table-striped">
                                        <tbody>
                                        <tr>
                                            <td>Total Antrean</td>
                                            <td class="table-info"><strong>{{resume.totalAntrean||0}}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Sudah dipanggil </td>
                                            <td class="table-success"><strong>{{resume.totalDone||0}}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Sisa Antrean </td>
                                            <td class="table-warning"><strong>{{resume.totalSisa||0}}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>VOID</td>
                                            <td class="table-danger"><strong>{{resume.totalVoid||0}}</strong></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                            <h5 class="card-title font-weight-semibold">Statistik </h5>
                            </div>
                            <div class="card-body">
                            <div class="chart-container">
                                <BarChart :chartData="dataAntreanStatistik"
                                    :chartOptions="chartOptions"
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabRanap">
                    <h2>Antrian Rawat Inap</h2>
                </div>
                <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane fade'" id="pasienTabFisio">
                    <h2>Antrian Fisioterapi</h2>
                </div>
            </div>
        </div>
        
		<validation-observer ref="VFormAntreanAdmisi">
            <b-modal v-model="openAntrianAdmisi" :title="'Antrian Admisi'" size="sm" hide-footer>    
                <div class="text-center" v-if="openAntrianAdmisi">
                    <div class="modal-shout-icon mb-3 d-inline-block alpha-info text-info rounded-circle">
                        <i class="icon-megaphone"></i>
                    </div>
                    <h6 class="mb-0">Memanggil Antrean</h6>
                    <h3 class="font-weight-semibold mb-0" style="font-size:36px;"> No.
                    {{latestAntrean[previx+'no_antrian']||"-"}}</h3>
                    <small class="text-info mb-3">Pasien akan berubah status void setelah dipanggil 3x</small>
                    
                    <div v-if="latestAntrean[previx+'called'] > 3" class="form-group text-left mt-3" id="patientNotPresenceInfo">
                        <label for="patientNotPresent">Catatan Ketidakhadiran Pasien<strong class="text-danger">*</strong></label>
                        <textarea v-model="latestAntrean[previx+'notes']" name="patientNotPresent" id="patientNotPresent" rows="3" class="form-control"
                        placeholder="cth. pasien tidak hadir setelah dipanggil 3x"></textarea>
                        
                        <VValidate :name="'Catatan'" v-model="latestAntrean[previx+'notes']" :rules="{required: 1, min: 2}" />

                    </div>

                    <div v-if="latestAntrean[previx+'called'] > 3" class="mt-1">       
                        <a href="javascript:;" @click="voidPasien(latestAntrean)" class="btn btn-danger btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-checkmark"></i></b>VOID
                        </a>
                    </div>

                    <div v-else class="mt-1">

                        <a href="javascript:;" v-if="!btnPanggil" @click="panggilPasien(latestAntrean)" class="btn btn-warning btn-labeled btn-labeled-left">
                        <b><i class="icon-bell2"></i></b> Panggil Ulang
                        </a>
                        
                        <a href="javascript:;" v-else class="btn btn-secondary btn-labeled btn-labeled-left">
                        <b><i class="icon-bell2"></i></b> Memanggil ...
                        </a>
                    </div>
                    
                    <div class="mt-1">
                        <a href="javascript:;" @click="hadirPasien(latestAntrean)" class="btn btn-success btn-labeled btn-labeled-left mr-1">
                        <b><i class="icon-checkmark"></i></b>Hadir
                        </a>
                    </div>
                </div>
            </b-modal>
        </validation-observer>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
const moment = require('moment')
import $ from 'jquery'
import Gen from '@/libs/Gen.js'
import BarChart from '@/components/chart/BarChart'


export default {
    extends: GlobalVue,  
    
    computed: {    
        urlApi(){ 
            let url = '' 
            if(this.activeTab == 1){
                url = 'UGDAdmisiAntrean'
            }else if(this.activeTab == 2){
                url = 'RanapAdmisiAntrean'
            }else{
                url = 'FisioAdmisiAntrean'
            }
            return url
        },
        prefix(){
            let prefix = '' 
            if(this.activeTab == 1){
                prefix = 'aadmu_'
            }else if(this.activeTab == 2){
                prefix = 'aadmran_'
            }else{
                prefix = 'aadmf_'
            }
            return prefix
        }
    }, 
    data() {
        return {
            unit : {},
            latestAntrean : {},
            resume : {
                totalAntrean : 0,
                totalVoid : 0,
                totalDone : 0
            },
            openAntrianAdmisi: false,
            dataAntreanStatistik: {},
            chartOptions: {
                responsive: true
            },
            btnPanggil: false,
            isShow: false,
            activeTab: 1
        }
    },
    components:{
        BarChart
    },
    methods: {
        changeTab(tab){
            this.activeTab = tab
            this.apiGet()
        },
        ambilAntrean(){
            this.loadingOverlay = true
            Gen.apiRest(
                "/do/"+this.urlApi,
                {data:{type:'take-antrian', id: this.latestAntrean[this.previx+'id']}}, 
                "POST"
            ).then(()=>{
                this.$socket.emit('refresh_data', {to : 'UGDAdmisi'})
                this.$socket.emit('refresh_data_all',{})
                this.loadingOverlay = false
                this.openAntrianAdmisi = true
                this.isShow = false
                this.panggilPasien(this.latestAntrean)

            })
        },
        panggilPasien(antrean){
            this.btnPanggil = true
            let calledField = (this.previx+'called')
            let dataPost = {
                type:'panggil-pasien', 
                id: antrean[this.previx+'id'], 
            }
            
            dataPost[calledField] = (this.latestAntrean[this.previx+'called'] + 1)
            
            Gen.apiRest(
                "/do/"+this.urlApi,
                {data:dataPost}, 
                "POST"
            ).then(()=>{
                antrean.to = 'UGDAdmisi'
                this.$socket.emit('panggil_pasien', antrean)
                this.latestAntrean[this.previx+'called'] = this.latestAntrean[this.previx + 'called'] + 1    
                setTimeout(()=>{
                    this.btnPanggil = false
                },5000)     
                this.isShow = true
            }).catch(()=>{
                setTimeout(()=>{
                    this.btnPanggil = false
                },5000)     
            })
        },
        voidPasien(antrean){
            this.$refs['VFormAntreanAdmisi'].validate().then(success=>{

                let calledField = (this.previx+'status')
                let dataPost = {
                    type:'update-status', 
                    id: antrean[this.previx + 'id'], 
                }
                dataPost[calledField] = 'VOID'

                if(success){
                    Gen.apiRest(
                        "/do/"+this.urlApi,
                        {data:dataPost}, 
                        "POST"
                    ).then(()=>{
                        this.openAntrianAdmisi = false
                        this.apiGet()
                        this.$socket.emit('refresh_data', {to : 'UGDAdmisi'})
                        this.$socket.emit('refresh_data_all',{})
                        return this.$swal({
                            icon: 'success',
                            title: 'Berhasil mengubah status antrean'
                        })
                    })
                }
            })
        },
        hadirPasien(antrean){
            let calledField = (this.previx+'status')
            let dataPost = {
                type:'update-status', 
                id: antrean[this.previx + 'id'], 
            }
            dataPost[calledField] = 'DONE'

            Gen.apiRest(
                "/do/"+this.urlApi,
                {data:dataPost}, 
                "POST"
            ).then(()=>{
                this.openAntrianAdmisi = false
                this.apiGet()
                this.$socket.emit('refresh_data', {to : 'UGDAdmisi'})
                this.$socket.emit('refresh_data_all',{})
                return this.$swal({
                    icon: 'success',
                    title: 'Berhasil mengubah status antrean'
                }).then(()=>{
                    console.log("sini")
                    this.loadingOverlay = true
                    let data = {
                      type : 'add-new-reservasi' 
                    }

                    Gen.apiRest(
                        "/do/"+this.urlApi,
                        {data:data}, 
                        "POST"
                    ).then(res=>{
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
            
                        this.$router.push({ name: 'UGDReservasi', params: { pageSlug: resp.data.id} }).catch(()=>{})
                    })
                })
            })
        },

        apiGet(params = {}, withLoad = false){
            this.data.data = false
            let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
            this.loadingOverlay = true
        
            if(params.slugs) slugs = params.slugs
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
        
            Gen.apiRest(
                "/get/"+this.urlApi+
                slugs, 
                {
                params: Object.assign({}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
            }).catch(()=>{
                this.loadingOverlay = false
            })
        },

    },
    mounted(){
        this.sockets.subscribe('disp_refresh_data_all', function(data) {
            if(!this.isPlay){
                this.apiGet()
            }
        })
        this.apiGet()
    },
    watch:{
        $route(){
            this.apiGet()
        },
        '$route.params.pageSlug':function(){
            this.$set(this,'filter',{})
        }
    },
    sockets: {
        connect() {
            this.isConnected = true
        },

        disconnect() {
            this.isConnected = false
        },

        messageChannel(data) {
            this.socketMessage = data
        },
    },
}
</script>